import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Components
import {
  TitleDefault,
  TitleSubDefault,
  ContentDefault,
} from 'components/Elements'
import SidebarMenu from 'components/Sidebar/SidebarMenu'
import Sidebar from 'components/Sidebar'
import Layout from 'components/Layout'
import CustomLink from 'components/CustomLink'
import SEO from 'components/SEO'
import ParseContent from 'components/ParseContent'

import { map as _map } from 'lodash'

// Images
import textlinkIcon from 'img/textlink-icon.svg'

// Styles
import '../styles/templates/ServiceTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    service: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      title
      wordpress_id
      acf {
        services_content
        header_image_detail_service {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        title_products
      }
      yoast_meta {
        name
        content
        property
      }
    }
    serviceProducts: allWordpressPage(
      filter: { parent_element: { wordpress_id: { eq: $wordpress_id } } }
      sort: { fields: acf___sortnumber, order: ASC }
    ) {
      edges {
        node {
          title
          path
          acf {
            excerpt_title
            excerpt_text
            excerpt_link_text
          }
        }
      }
    }
  }
`
function ServiceTemplate({
  data: { service, serviceProducts },
  pageContext: {
    breadcrumb: { crumbs },
  },
}) {
  return (
    <Layout subFooter>
      {service.acf.header_image_detail_service &&
      service.acf.header_image_detail_service.localFile ? (
        <SEO
          yoast={{ meta: service.yoast_meta }}
          pathname={service.path}
          image={
            service.acf.header_image_detail_service.localFile.childImageSharp
              .fixed.src
          }
          article
        />
      ) : (
        <SEO yoast={{ meta: service.yoast_meta }} pathname={service.path} />
      )}
      <div className="service-template">
        <section className="heroimage position-relative d-flex">
          <div className="container context">
            <div className="row py-4">
              <div className="col-lg-6">
                <TitleDefault className="mb-3 color-text-light">
                  {service.title}
                </TitleDefault>
                <div className="line mb-4" />
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=">"
                  crumbLabel={service.title}
                />
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
          <div className="heroimage-image row position-absolute">
            <div className="col-12 col-lg-5 fill" />
            <div className="heroimage-image-container col-lg-7 p-0">
              <BackgroundImage
                className="bgimage"
                fluid={
                  service.acf.header_image_detail_service.localFile
                    .childImageSharp.fluid
                }
              />
            </div>
          </div>
        </section>
        <section className="service-content">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-3 col-md-5 pl-lg-0 pr-lg-5">
                <SidebarMenu currentPageID={service.wordpress_id} />
              </div>
              <div className="col-lg-6 col-md-7 pr-lg-5 py-4 py-lg-0">
                <TitleDefault className="mb-lg-4">{service.title}</TitleDefault>
                <ContentDefault>
                  <ParseContent content={service.acf.services_content} />
                </ContentDefault>
              </div>
              <div className="col-lg-3 pr-lg-0">
                <Sidebar />
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-lg-3 d-none d-lg-block" />
              <div className="col-lg-9 serviceproducts">
                {service.acf.title_products && (
                  <TitleDefault className="mb-lg-4">
                    {service.acf.title_products}
                  </TitleDefault>
                )}
                {_map(serviceProducts.edges, (product, index) => (
                  <div key={index} className="row pb-4">
                    <div className="col-lg-10">
                      <TitleSubDefault>
                        {product.node.acf.excerpt_title}
                      </TitleSubDefault>
                      <ContentDefault>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.node.acf.excerpt_text,
                          }}
                        />
                      </ContentDefault>
                      <CustomLink className="textlink" to={product.node.path}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: product.node.acf.excerpt_link_text,
                          }}
                        />
                        <img src={textlinkIcon} width="10" alt="" />
                      </CustomLink>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ServiceTemplate
